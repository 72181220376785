import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import { CellMeasurerCache } from "react-virtualized";
import { Checkbox, InputAdornment, MenuItem, debounce } from "@mui/material";
import {
	translate,
	hasPermission,
	hasCompanyPermission,
	Permissions,
	isFeatureEnabled,
	Flags,
} from "../../../../common/providers";
import styles from "./SearchTemplateResult.module.css";
import {
	CustomDialog,
	CustomSwitch,
	generateFilters,
	I18nTranslate,
	icon,
	IconComponent,
	InfiniteList2,
	InfiniteListSelector,
	Preview,
	SearchRow,
	VerticalDivider,
} from "../../../../common/components";
import { ApiService, BackOfficeService, SearchCardTemplateService } from "../../../../api";
import { usePrevious } from "../../../../common/hooks";
import { SEARCH_TYPES } from "../../../../common/constants/search-types";
import { isNonEmptyObject } from "../../../../common/utils";
import Sort from "../../../../common/components/drop-down/sort-drop-down/Sort";
import { setSortAlgorithm } from "../../my-search-template/slice/my-search-template-slice";
import { SCORING_SORT_AVAILABLE } from "../../../../common/constants";

const cache = new CellMeasurerCache({
	fixedWidth: true,
	minHeight: 150,
});
const debouncedFunction = debounce((func) => func(), 100);

export default function SearchTemplateResult({
	getTotalResults,
	search,
	setIsFilterTocOut,
	setIsLoadingSearch,
	setSelectedProject,
	setTotalMatches,
	slice,
}) {
	const dispatch = useDispatch();
	const [checkAll, setCheckAll] = useState(false);
	const [resultCount, setResultCount] = useState(0);
	const [preview, setPreview] = useState(null);
	const [checkedRows, setCheckedRows] = useState([]);
	const [idsToExclude, setIdsToExclude] = useState([]);
	const [resultsRequest, setResultsRequest] = useState(null);
	const [openDisplayTitlesDialog, setOpenDisplayTitlesDialog] = useState(false);
	const [updatingRequest, setUpdatingRequest] = useState(false);
	const hasFilters = useSelector((state) => state[slice].hasFilters);
	const results = useSelector((state) => state[slice].results);
	const totalResults = useSelector((state) => state[slice].totalResults);
	const totalMatches = useSelector((state) => state[slice].totalMatches);
	const filters = useSelector((state) => state[slice].filters);
	const separator = useSelector((state) => state[slice].separator);
	const isFilterTocOut = useSelector((state) => state[slice].isFilterTocOut);
	const selectedProject = useSelector((state) => state[slice].selectedProject);
	const isDsi = useSelector((state) => state[slice].isDsi);
	const selectedSortAlgo = useSelector((state) => state[slice].selectedSortAlgo);
	const sortingAlgorithms = useSelector((state) => state[slice].sortingAlgorithms);
	const [projectsRequest, setProjectsRequest] = useState(null);
	const pSeparator = usePrevious(separator);
	const pFilters = usePrevious(filters);
	const cancelTokenSourceRef = useRef(null);
	const listRef = useRef(null);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		setProjectsRequest(
			() =>
				({ limit, page }) =>
					(isDsi ? BackOfficeService.getTemplateProjects : SearchCardTemplateService.getProjects)(
						cancelTokenSourceRef.current.token,
						{
							limit,
							page,
						}
					)
		);
	}, [isDsi]);
	useEffect(() => {
		if (selectedProject && hasFilters) {
			setUpdatingRequest(true);
			setResultsRequest(
				(isNonEmptyObject(filters) &&
					(() =>
						({ limit, page }) =>
							(isDsi ? BackOfficeService.templateSearch : SearchCardTemplateService.templateSearch)(
								{ projectId: selectedProject.projectId || selectedProject.id },
								generateFilters({
									Type: SEARCH_TYPES.template,
									...(filters || {}),
									isFilterTocOut,
									...((SCORING_SORT_AVAILABLE && { sort: selectedSortAlgo }) || {}),
								}),
								{
									limit,
									page,
								},
								cancelTokenSourceRef.current.token
							)
								.then((r) => {
									dispatch(setTotalMatches(r.details.totalElements));
									return r.details;
								})
								.catch(console.error))) ||
					null
			);
			debouncedFunction(() => {
				setUpdatingRequest(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, isDsi, selectedProject, separator, hasFilters, selectedSortAlgo]);

	const refreshRefCache = (rowIndex) => {
		cache.clearAll();
		listRef.current?.recomputeRowHeights(rowIndex ?? 0);
	};
	const handleReset = () => {
		setCheckAll(false);
		setCheckedRows([]);
		setResultCount(0);
		setIdsToExclude([]);
	};
	useEffect(() => {
		if ((pFilters && filters && pFilters !== filters) || (pSeparator && separator && pSeparator !== separator)) {
			refreshRefCache();
			handleReset();
		}
	}, [pFilters, filters, pSeparator, separator]);

	useEffect(() => {
		const cancelToken = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelToken);
		};
	}, [selectedProject]);

	useEffect(() => {
		setResultCount(results.length);
	}, [results]);

	useEffect(() => {
		if (checkAll) {
			setCheckedRows((prev) => [
				...prev,
				...results.filter((r, index) => index >= resultCount).map((row) => ({ ...row, checked: true })),
			]);
			setResultCount(results.length);
		}
	}, [checkAll, results, resultCount]);
	useEffect(() => {
		if (isNonEmptyObject(filters) && selectedSortAlgo) {
			dispatch(
				search({
					filters: generateFilters(filters),
					page: 0,
					limit: 25,
					sort: selectedSortAlgo,
					token: cancelTokenSourceRef.current.token,
				})
			);
		}
		dispatch(getTotalResults(cancelTokenSourceRef.current.token));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, selectedProject]);
	const handleCheckRow = (checked, row) => {
		if (checked) {
			setCheckedRows((prev) => [...prev, row]);
			if (checkAll) {
				setIdsToExclude((prev) => prev.filter((id) => id !== row.informationId));
			}
		} else {
			setCheckedRows((prev) => prev.filter((r) => r.informationId !== row.informationId));
			if (checkAll) {
				setIdsToExclude((prev) => [...prev, row.informationId]);
			}
		}
	};
	const handleCloseDisplay = () => setPreview(null);
	const handleCheckAllRows = (e) => {
		const { checked } = e.target;
		setCheckAll(checked);
		if (checked) {
			setIdsToExclude([]);
		}
		setCheckedRows(checked ? results : []);
	};
	const handleDisplay = (informationId, documentId, page, informationType) =>
		setPreview({
			coordinates: results.find((res) => res.informationId === informationId)?.rectangleDTO,
			documentId,
			page,
			informationId,
			informationType,
		});

	const rowRenderer2 = (row) => {
		const {
			attributes,
			thematic,
			clause,
			content,
			documentId,
			documentName,
			informationId,
			page,
			reqId,
			type,
			location,
			documentFilename,
		} = row;
		return (
			<div className={styles.result__row}>
				<SearchRow
					isTemplate
					attributes={attributes}
					categories={thematic}
					clause={clause}
					content={content}
					documentFilename={documentFilename}
					documentId={documentId}
					documentName={documentName}
					informationId={informationId}
					informationType={type}
					isCheckboxDisplayed={
						isFeatureEnabled(Flags.SEARCHCARD_ACTIONS) &&
						hasPermission(Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER) &&
						hasCompanyPermission(Permissions.PROJECT_LEADER)
					}
					isChecked={checkedRows.some((r) => r.informationId === informationId)}
					location={location}
					page={page}
					reqId={reqId}
					row={row}
					onCheck={handleCheckRow}
					onDisplay={handleDisplay}
				/>
			</div>
		);
	};
	const endAdornmentRenderer = (cond) => (
		<InputAdornment position="end">
			<IconComponent color="var(--color-dark-grey-1)" icon={cond ? icon.faCaretUp : icon.faCaretDown} />
		</InputAdornment>
	);
	const selectorRowRenderer = ({ loadedRow, onClick }) => (
		<MenuItem key={loadedRow?.id} onClick={() => onClick(loadedRow)}>
			<span>{loadedRow?.projectName || loadedRow?.name || ""}</span>
		</MenuItem>
	);
	const handleChangeProjectInput = (newProject) => {
		batch(() => {
			dispatch(setSelectedProject(newProject));
			dispatch(getTotalResults(cancelTokenSourceRef.current.token));
		});
	};
	const handleCloseDisplayTitlesDialog = () => {
		setOpenDisplayTitlesDialog(false);
	};
	const handleChangeDisplayTitle = () => {
		if (!isFilterTocOut) {
			setOpenDisplayTitlesDialog(true);
		} else {
			batch(() => {
				dispatch(setIsLoadingSearch(true));
				dispatch(setIsFilterTocOut(false));
				dispatch(
					search({
						filters: generateFilters(filters),
						page: 0,
						limit: 25,
						...((SCORING_SORT_AVAILABLE && selectedSortAlgo && { sort: selectedSortAlgo }) || {}),
						token: cancelTokenSourceRef.current.token,
					})
				);
			});
		}
	};
	const handleChangeSort = (algo) => {
		batch(() => {
			dispatch(setSortAlgorithm(algo));
			dispatch(setIsLoadingSearch(true));
		});
	};
	const handleValidateHideTitles = () => {
		batch(() => {
			if (selectedSortAlgo) {
				dispatch(setIsLoadingSearch(true));
				dispatch(setIsFilterTocOut(true));
				dispatch(
					search({
						filters: generateFilters(filters),
						page: 0,
						limit: 25,
						sort: selectedSortAlgo,
						token: cancelTokenSourceRef.current.token,
					})
				);
			}
		});
		handleCloseDisplayTitlesDialog();
	};
	const selectedCount = checkAll ? totalMatches - idsToExclude.length : checkedRows.length;
	const handlePlural = (elements, string) => {
		const translateType = "search-template.results.count-";
		if (elements > 1) {
			return translateType.concat(string);
		}
		return translateType.concat("single-", string);
	};
	return (
		<>
			<div className={styles.result}>
				<div className={styles.result__results}>
					{isFeatureEnabled(Flags.SEARCHCARD_ACTIONS) &&
						hasPermission(Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER) &&
						hasCompanyPermission(Permissions.PROJECT_LEADER) && (
							<Checkbox
								checked={checkedRows.length > 0}
								color="primary"
								indeterminate={checkedRows.length > 0 && checkedRows.length < results.length}
								size="medium"
								onChange={handleCheckAllRows}
							/>
						)}
					<span className={styles.results__count}>
						{hasFilters &&
							((checkedRows.length === 0 && (
								<I18nTranslate
									param={{
										totalMatches,
										totalResults,
									}}
									translationKey={handlePlural(totalMatches, "match")}
								/>
							)) || (
								<I18nTranslate
									param={{
										totalMatches,
										nbSelected: selectedCount,
									}}
									translationKey={handlePlural(selectedCount, "selected-match")}
								/>
							))}
						{!hasFilters &&
							!isDsi &&
							((checkedRows.length === 0 && (
								<I18nTranslate
									param={{ totalResults }}
									translationKey={handlePlural(totalResults, "total")}
								/>
							)) || (
								<I18nTranslate
									param={{
										totalResults,
										nbSelected: selectedCount,
									}}
									translationKey={handlePlural(selectedCount, "selected-total")}
								/>
							))}
						<InfiniteListSelector
							classes={{ input: styles.infiniteSelector }}
							className={styles.projectSelector}
							classNameInfiniteList={styles.infiniteSelector__list}
							endAdornmentRenderer={endAdornmentRenderer}
							infiniteListRequest={projectsRequest}
							rowRenderer={selectorRowRenderer}
							value={
								selectedProject?.projectName ||
								selectedProject?.name ||
								translate("search-template.add-template-panel.project-select.project")
							}
							onClick={handleChangeProjectInput}
						/>
					</span>
					{checkedRows.length > 0 && (
						<>
							<VerticalDivider height={40} />
							<span className={styles["results--text"]}>
								{translate("search-template.results.actions")}
							</span>
						</>
					)}
					<span className={styles.results__spacer} />
					<span className={styles.results__displayTitles}>
						<CustomSwitch checked={!isFilterTocOut} onChange={handleChangeDisplayTitle} />
						{translate("search-template.results.display-titles")}
					</span>
					{(SCORING_SORT_AVAILABLE && (
						<Sort
							selectedSortAlgo={selectedSortAlgo}
							sortingAlgorithms={sortingAlgorithms}
							onChangeSort={handleChangeSort}
						/>
					)) || (
						<span className={styles.results__sort}>
							<IconComponent color="var(--color-light-grey-2)" icon={icon.faSortAmountDown} size="xs" />
							{translate("search-template.results.sort.precedence")}
						</span>
					)}
				</div>
				{(hasFilters && (
					<div className={styles.results__listWrapper}>
						{!updatingRequest && resultsRequest && hasFilters && (
							<InfiniteList2 request={resultsRequest} rowRenderer={rowRenderer2} />
						)}
					</div>
				)) || <div className={styles.startFiltering}>{translate("search-templates.no-filters")}</div>}
			</div>
			<CustomDialog
				iconColor="var(--color-red)"
				iconModel={icon.faExclamationTriangle}
				open={openDisplayTitlesDialog}
				submitLabel={translate("search-template.results.warning-dialog.confirm")}
				subTitle={translate("search-template.results.warning-dialog.text")}
				title={translate("search-template.results.warning-dialog.title")}
				onClose={handleCloseDisplayTitlesDialog}
				onSubmit={handleValidateHideTitles}
			/>
			{(selectedProject?.projectId || selectedProject?.id) && (
				<Preview
					coordinates={preview?.coordinates}
					docId={preview?.documentId}
					infoId={preview?.informationId}
					infoType={preview?.informationType}
					open={!!preview}
					page={preview?.page}
					projectId={selectedProject.projectId || selectedProject.id}
					onClose={handleCloseDisplay}
				/>
			)}
		</>
	);
}
