import { createSlice } from "@reduxjs/toolkit";
import { KEYWORD_FILTER_TYPE, OPERATORS, VISIBILITY } from "../../../../common/constants";
import { translateEnumParam, translateEnumParamTooltip } from "../../../../common/providers";
import { alphaSort } from "../../../../common/utils";
import { CRITICALITY, NEGOTIABILITY, TYPE } from "../../utils/utils";
import {
	countResults,
	createSearch,
	deleteSearch,
	editSearch,
	getAllSearches,
	getCriticalityParameters,
	getNegotiabilityParameters,
	getCategoriesParents,
	getCategoriesChildren,
	getTypeParameters,
	search,
	updateSearchFilters,
	getSortingAlgorithms,
} from "./my-search-thunks";
import { computeKeywordFiltersExistence, hasBeenModified, removeNullFilters, setNewResult } from "./my-search-utils";
import { adjustFiltersFromPayload } from "../../../../common/components";

const initFilter = { separator: OPERATORS.AND, Type: "InformationSearchFilterDTO", sort: "SCORING" };
const LIST_KEY_MY_SEARCHES = "mySearches";
const initialState = {
	mySearches: [],
	projectSearches: [],
	items: [],
	currentSearch: {
		isNew: true,
		id: 0,
		creatorId: 0,
		title: "",
		description: "",
		visibility: VISIBILITY.PRIVATE,
		listKey: "", // When currentSearch is not new, it identifies from which list it came from
	},
	modified: false,
	hasFilters: false,
	numberOfPositiveKeywordFilters: { [KEYWORD_FILTER_TYPE.PARAGRAPH]: 0, [KEYWORD_FILTER_TYPE.TOC]: 0 },
	totalResults: 0,
	totalMatches: 0,
	results: [],
	isFilterTocOut: false,
	filters: initFilter,
	previousIsFilterTocOut: false,
	previousFilters: initFilter,
	page: 0,
	limit: 20,
	isLoadingSearch: false,
	isLoadingParameters: false,
	isLoadingCategories: false,
	types: [],
	criticalities: [],
	negotiabilities: [],
	hasMore: false,
	totalPages: 0,
	isUpdating: false,
	selectedSortAlgo: "",
	sortingAlgorithms: [],
};

const mySearchSlice = createSlice({
	name: "mySearch",
	initialState,
	reducers: {
		setSortAlgorithm: (state, { payload }) => ({ ...state, selectedSortAlgo: payload }),
		setTotalResults: (state, { payload }) => ({ ...state, totalResults: payload }),
		setItems: (state, { payload }) => ({ ...state, [payload.key]: payload.items }),
		setIsLoadingSearch: (state, { payload }) => ({ ...state, isLoadingSearch: payload }),
		setIsUpdating: (state, { payload }) => ({ ...state, isUpdating: payload }),
		setIsFilterTocOut: (state, { payload }) => ({
			...state,
			isFilterTocOut: payload,
			modified: hasBeenModified({ ...state, isFilterTocOut: payload }),
		}),

		setCurrentSearch: (state, { payload }) => {
			const { currentSearch, filters, isFilterTocOut, ...rest } = payload;
			if (filters) {
				const cleanFilters = adjustFiltersFromPayload(removeNullFilters(filters));
				const { numberOfPositiveKeywordFilters } = computeKeywordFiltersExistence(cleanFilters);
				return {
					...state,
					currentSearch,
					modified: false,
					hasFilters: Object.keys(cleanFilters).some((item) => item !== "Type" && item !== "separator"),
					filters: cleanFilters,
					previousFilters: cleanFilters,
					isFilterTocOut,
					previousIsFilterTocOut: isFilterTocOut,
					numberOfPositiveKeywordFilters,
					...rest,
				};
			}
			return {
				...state,
				currentSearch,
				...rest,
			};
		},
		setFilters: (state, { payload: filters }) => {
			const newFilters = removeNullFilters(filters);
			const { numberOfPositiveKeywordFilters } = computeKeywordFiltersExistence(newFilters);
			return {
				...state,
				filters: newFilters,
				hasFilters: Object.keys(newFilters).some((item) => item !== "Type" && item !== "separator"),
				modified: hasBeenModified({ ...state, filters: newFilters }),
				numberOfPositiveKeywordFilters,
			};
		},
		updateSearchResultCount: (state, { payload }) => {
			const { id, resultCount, listKey } = payload;
			if (!listKey) {
				return;
			}
			const lookupSearch = state[listKey].find((s) => s.id === id);
			if (lookupSearch) {
				lookupSearch.resultCount = resultCount;
			}
		},
		updateResultRows: (state, { payload }) => {
			const { ids, checkAll, idsToExclude, ...t } = payload;
			if (checkAll) {
				return {
					...state,
					results: state.results.map((res) =>
						!idsToExclude.includes(res.informationId) ? setNewResult(res, t) : res
					),
				};
			}
			return {
				...state,
				results: state.results.map((res) => (ids.includes(res.informationId) ? setNewResult(res, t) : res)),
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getCategoriesParents.fulfilled, (state, action) => ({
			...state,
			isLoadingCategories: false,
			categories: action.payload,
		}));
		builder.addCase(getTypeParameters.fulfilled, (state, action) => {
			const types = action.payload.map((i) => ({ name: i, displayName: translateEnumParam(TYPE, i) }));
			return { ...state, isLoadingParameters: false, types };
		});
		builder.addCase(getTypeParameters.pending, (state) => ({ ...state, isLoadingParameters: true }));
		builder.addCase(getTypeParameters.rejected, (state) => ({ ...state, isLoadingParameters: false }));
		builder.addCase(getCriticalityParameters.fulfilled, (state, action) => {
			const criticalities = action.payload.map((i) => ({
				name: i,
				displayName: translateEnumParam(CRITICALITY, i),
			}));
			return { ...state, isLoadingParameters: false, criticalities };
		});
		builder.addCase(getCriticalityParameters.pending, (state) => ({ ...state, isLoadingParameters: true }));
		builder.addCase(getCriticalityParameters.rejected, (state) => ({ ...state, isLoadingParameters: false }));
		builder.addCase(getNegotiabilityParameters.fulfilled, (state, action) => {
			const negotiabilities = action.payload.map((i) => ({
				name: i,
				displayName: translateEnumParam(NEGOTIABILITY, i),
				title: translateEnumParamTooltip(NEGOTIABILITY, i),
			}));
			return { ...state, isLoadingParameters: false, negotiabilities };
		});
		builder.addCase(getNegotiabilityParameters.pending, (state) => ({ ...state, isLoadingParameters: true }));
		builder.addCase(getNegotiabilityParameters.rejected, (state) => ({ ...state, isLoadingParameters: false }));
		builder.addCase(getAllSearches.fulfilled, (state, action) => {
			const { contents } = action.payload;
			const { key } = action.meta.arg;
			return {
				...state,
				[key]: contents,
			};
		});
		builder.addCase(getSortingAlgorithms.fulfilled, (state, action) => ({
			...state,
			sortingAlgorithms: action.payload,
			selectedSortAlgo: action.payload[0],
		}));
		builder.addCase(search.fulfilled, (state, action) => {
			const { hasFilters } = state;
			const {
				details: { contents, totalElements, pageNumber, hasMore, totalPages },
			} = action.payload;
			const newState = {
				...state,
				isLoadingSearch: false,
				results: pageNumber === 0 ? contents : [...state.results, ...contents],
				page: pageNumber,
				hasMore,
				totalPages,
			};
			if (hasFilters) {
				newState.totalMatches = totalElements;
			} else {
				newState.totalMatches = totalElements;
				newState.totalResults = totalElements;
			}
			return newState;
		});
		builder.addCase(search.rejected, (state) => ({
			...state,
			hasMore: false,
			totalPages: 0,
			isLoadingSearch: false,
		}));
		builder.addCase(createSearch.fulfilled, (state, action) => {
			if (action.payload.visibility !== "AUTOMATION") {
				const currentSearch = { isNew: false, listKey: LIST_KEY_MY_SEARCHES, ...action.payload };
				const cleanFilters = removeNullFilters(state.filters);
				const { numberOfPositiveKeywordFilters } = computeKeywordFiltersExistence(cleanFilters);
				const mySearches = [action.payload, ...state.mySearches];
				mySearches.sort((s1, s2) => alphaSort(s1.title, s2.title));
				return {
					...state,
					hasFilters: Object.keys(cleanFilters).some((item) => item !== "Type" && item !== "separator"),
					filters: cleanFilters,
					previousFilters: cleanFilters,
					modified: false,
					currentSearch,
					mySearches,
					numberOfPositiveKeywordFilters,
					isUpdating: true,
				};
			}
			return { ...state };
		});
		builder.addCase(updateSearchFilters.fulfilled, (state, action) => {
			const { id, resultCount, title, visibility, description, creatorId } = action.payload;
			const cleanFilters = removeNullFilters(state.filters);
			const { numberOfPositiveKeywordFilters } = computeKeywordFiltersExistence(cleanFilters);
			state.currentSearch = {
				isNew: false,
				id,
				title,
				visibility,
				description,
				creatorId,
				listKey: LIST_KEY_MY_SEARCHES,
			};
			state.mySearches.find((s) => s.id === id).resultCount = resultCount;
			state.modified = false;
			state.hasFilters = Object.keys(cleanFilters).some((item) => item !== "Type" && item !== "separator");
			state.filters = cleanFilters;
			state.previousFilters = cleanFilters;
			state.previousIsFilterTocOut = state.isFilterTocOut;
			state.numberOfPositiveKeywordFilters = numberOfPositiveKeywordFilters;
		});
		builder.addCase(deleteSearch.fulfilled, (state, action) => {
			const { searchCardId } = action.meta.arg;
			const { id } = state.currentSearch;
			const mySearches = state.mySearches.filter((s) => s.id !== searchCardId);
			if (id === searchCardId) {
				return {
					...state,
					mySearches,
					currentSearch: {
						isNew: true,
						id: 0,
						creatorId: 0,
						title: "",
						description: "",
						visibility: VISIBILITY.PRIVATE,
						listKey: "",
					},
					isLoadingSearch: true,
					hasFilters: false,
					numberOfPositiveKeywordFilters: 0,
					modified: false,
					filters: initFilter,
					previousFilters: initFilter,
					totalMatches: 0,
					page: 0,
				};
			}
			return { ...state, mySearches };
		});
		builder.addCase(editSearch.fulfilled, (state, action) => {
			const { searchCardId } = action.meta.arg;
			const { id } = state.currentSearch;
			const mySearches = [action.payload, ...state.mySearches.filter((s) => s.id !== searchCardId)];
			mySearches.sort((s1, s2) => alphaSort(s1.title, s2.title));
			if (id === searchCardId) {
				return {
					...state,
					mySearches,
					currentSearch: { isNew: false, listKey: LIST_KEY_MY_SEARCHES, ...action.payload },
					isUpdating: true,
				};
			}
			return { ...state, mySearches };
		});
		builder.addMatcher(
			({ type }) => typeof type === "string" && type.endsWith("rejected"),
			(_, { error }) => {
				console.error(error.message);
			}
		);
	},
});

export {
	countResults,
	createSearch,
	deleteSearch,
	editSearch,
	getAllSearches,
	getCategoriesParents,
	getCategoriesChildren,
	getCriticalityParameters,
	getNegotiabilityParameters,
	getTypeParameters,
	search,
	updateSearchFilters,
	getSortingAlgorithms,
};
export const {
	setItems,
	setFilters,
	setCurrentSearch,
	setIsLoadingSearch,
	updateSearchResultCount,
	setIsFilterTocOut,
	setTotalResults,
	setIsUpdating,
	updateResultRows,
	setSortAlgorithm,
} = mySearchSlice.actions;
export default mySearchSlice.reducer;
