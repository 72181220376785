import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
	CustomIconButton,
	CustomIconButtonLink,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	icon,
} from "../../../../../../common/components";
import { translate, translateDate } from "../../../../../../common/providers";
import { NavUtils } from "../../../../../../navigation";
import PriorityCell from "../PriorityCell";
import TaskSelectStatus from "../TaskSelectStatus";
import styles from "./TasksTable.module.css";

export default function TasksTable({ taskList, onRowClick, currentUser, projectId, onDialogDelete }) {
	const location = useLocation();
	const [headers] = useState([
		{ name: translate("dashboards.task-dashboard.owner"), className: styles.firstColumnCell },
		{ name: translate("dashboards.task-dashboard.stakeholder") },
		{ name: translate("dashboards.task-dashboard.description") },
		{ name: translate("dashboards.task-dashboard.priority") },
		{ name: translate("dashboards.task-dashboard.status") },
		{ name: translate("dashboards.task-dashboard.deadline") },
		{ name: <span className={styles.table__iconHead} /> },
	]);
	const handleDeleteClick = (event, row) => {
		event.stopPropagation();
		onDialogDelete(row);
	};
	return (
		<CustomTable stickyHeader>
			<CustomTableHead>
				<CustomTableRow>
					{headers.map((header) => (
						<CustomTableCell key={header.name} className={`${header.className} ${styles.header}`}>
							{header.name}
						</CustomTableCell>
					))}
				</CustomTableRow>
			</CustomTableHead>
			<CustomTableBody emptyState={translate("dashboards.task-dashboard.no-content")}>
				{Array.isArray(taskList) &&
					taskList.map((row) => (
						<CustomTableRow
							key={row.taskId}
							hover
							className={styles.row}
							onClick={() => onRowClick(row.taskId)}
						>
							{({ isHovering }) => (
								<>
									<CustomTableCell align="left" className={styles.firstColumnCell}>
										{row.fromUser}
									</CustomTableCell>
									<CustomTableCell align="left">{row.toUser}</CustomTableCell>
									<CustomTableCell align="left">{row.description || ""}</CustomTableCell>
									<CustomTableCell align="left">
										<PriorityCell isAuthor={false} priority={row.priority} taskId={row.taskId} />
									</CustomTableCell>
									<CustomTableCell align="left">
										{currentUser && (
											<TaskSelectStatus
												isAuthor={false}
												status={row.taskStatus}
												taskId={row.taskId}
											/>
										)}
									</CustomTableCell>
									<CustomTableCell align="left">{translateDate(row.deadline)}</CustomTableCell>
									<CustomTableCell align="left" className={styles.cell__action}>
										{isHovering && (
											<>
												<CustomIconButtonLink
													btnClasName={styles.actionCell__btn}
													icon={icon.faChevronRight}
													state={{ previous: location.pathname }}
													to={NavUtils.goToSmartReview({
														projectId,
														documentId: row.documentId,
														pageStart: row.pageStart,
														infoId: row.informationId,
													})}
													tooltip={translate(
														"dashboards.task-dashboard.smart-view-redirecting"
													)}
												/>
												{row.fromUserEmail === currentUser.email && (
													<CustomIconButton
														icon={icon.faTrash}
														tooltip={translate("dashboards.task-dashboard.delete-task")}
														onClick={(event) => {
															handleDeleteClick(event, row);
														}}
													/>
												)}
												<CustomIconButton
													icon={icon.faInfoCircle}
													tooltip={translate("dashboards.task-dashboard.details")}
												/>
											</>
										)}
									</CustomTableCell>
								</>
							)}
						</CustomTableRow>
					))}
			</CustomTableBody>
		</CustomTable>
	);
}
