import React from "react";
import { AccordionDetails, Divider } from "@mui/material";
import { CustomIconButton, icon } from "../../../../../../../../../../common/components";
import { translate, translateDate } from "../../../../../../../../../../common/providers";
import styles from "../VersioningTabView.module.css";

export default function AccordionOriginal({
	documentInfo,
	handleClickDisplay,
	original,
	originalContent,
	previousUpload,
	previousVersion,
}) {
	return (
		<AccordionDetails
			classes={{
				root: styles.accordionDetails__root,
			}}
		>
			<Divider />
			<div className={styles.content}>
				<div className={styles.documentVersion}>
					<div className={styles.documentName}>
						<span>{documentInfo.previousVersionName}</span>
						<br />
						<span className={styles.uploaded}>{translateDate(previousUpload)}</span>
					</div>
					<div className={styles.button}>
						<CustomIconButton
							data-testid="file.icon.btn"
							icon={icon.faFile}
							size="sm"
							tooltip={translate("common:btn.display")}
							variant="outlined"
							onClick={() => handleClickDisplay(previousVersion)}
						/>
					</div>
				</div>
			</div>
			<Divider />
			<div className={styles.content}>
				<div className={styles.documentVersion}>
					<div className={styles.documentName}>
						<span>
							{original?.previousInfoId &&
								translate(
									documentInfo.sidePanelIsRequirement
										? "smart-review.cmp-details.option-panel.versioning.info-requirement"
										: "smart-review.cmp-details.option-panel.versioning.info-information",
									{
										infoId: original?.previousInfoId,
									}
								)}
						</span>
					</div>
				</div>
			</div>
			<Divider />
			<div className={styles.content}>{originalContent}</div>
		</AccordionDetails>
	);
}
