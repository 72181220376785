import React from "react";
import { useSelector } from "react-redux";
import { translate } from "../../../../../../../common/providers";
import CustomMagicDropDown from "../../../../../../../common/components/ChatDropDown/CustomMagicDropDown ";

export default function ReviewRedirectionId() {
	const selectedRectangles = useSelector(({ srDocument }) => srDocument.selectedRectangles);
	const sidePanelInformationId = useSelector(({ srDocument }) => srDocument.selectedSearch.infoId);
	const informationIds = selectedRectangles.map((rectangle) => rectangle.informationId);
	const questions = [
		{
			title: translate("smart-review.translate-paragraph"),
			choices: [
				{
					title: translate("smart-review.french-language"),
					text: translate("smart-review.paragraph-translate.question", {
						language: translate("smart-review.french-language"),
					}),
					summary: translate("smart-review.paragraph-translate.summary", {
						IdParagraph: informationIds,
					}),
				},
				{
					title: translate("smart-review.english-language"),
					text: translate("smart-review.paragraph-translate.question", {
						language: translate("smart-review.english-language"),
					}),
					summary: translate("smart-review.paragraph-translate.summary", {
						IdParagraph: informationIds,
					}),
				},
				{
					title: translate("smart-review.spanish-language"),
					text: translate("smart-review.paragraph-translate.question", {
						language: translate("smart-review.spanish-language"),
					}),
					summary: translate("smart-review.paragraph-translate.summary", {
						IdParagraph: informationIds,
					}),
				},
			],
		},
		{
			title: translate("smart-review-summarize-paragraph"),
			text: translate("smart-review-summarize.question"),
			summary: translate("smart-review-summarize-paragraph.summary", { IdParagraph: informationIds }),
		},
		{
			title: translate("smart-review.ask-another-question"),
			text: "",
		},
	];

	const handleGetRedirectToChatFilters = (value, summary) => ({
		state: {
			filters: {
				filter: {
					informationIds: {
						elements: [sidePanelInformationId],
						is: true,
						matchAll: false,
					},
					separator: "AND",
					Type: "QuickAccessSearchFilterDTO",
				},
				value,
				summary,
			},
		},
	});

	return (
		<CustomMagicDropDown
			positionDropDown="bottom-end"
			questions={questions}
			onGetRedirectToChatFilters={handleGetRedirectToChatFilters}
		/>
	);
}
