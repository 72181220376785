import React, { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import styles from "../add-user-dialog/AddUserDialog.module.css";
import { translate } from "../../../../common/providers";
import { CustomDialog } from "../../../../common/components";

export default function AddPartnerDialog({ open, onClose, onSubmit, companies }) {
	const [selectedCompany, setSelectedCompany] = useState(null);
	const handleSelectCompany = (e) => setSelectedCompany(e.target.value);
	const handleSubmit = () => {
		if (!selectedCompany) {
			return;
		}
		onSubmit(selectedCompany);
		onClose();
	};
	return (
		<CustomDialog
			isForm
			open={open}
			title={translate("dsi.add-partner-dialog.add-partner-to-project")}
			onClose={onClose}
			onSubmit={handleSubmit}
		>
			<FormControl
				fullWidth
				className={styles.formControl}
				label={translate("dsi.add-partner-dialog.label.select-company")}
				variant="outlined"
			>
				<InputLabel className={styles.inputLabel}>
					{translate("dsi.add-partner-dialog.label.select-company")}
				</InputLabel>
				<Select fullWidth size="small" value={selectedCompany || ""} onChange={handleSelectCompany}>
					{companies?.map((cny) => (
						<MenuItem key={cny.id} translate="no" value={cny}>
							{cny.companyName}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</CustomDialog>
	);
}
