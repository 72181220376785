import React, { useEffect, useCallback, useState, useRef, useMemo } from "react";
import { batch, useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
	setAllPrecedences,
	setClauseId,
	setFilters,
	setIsContextLoadedToRedux,
	setIsHandleScroll,
	setIsPdfScrolling,
	setTransverseFilters,
} from "../../slice/project-document/project-document-slice";
import {
	resetDocument,
	getDocumentDetails,
	getDocumentInformation,
	getDocumentPhysicalDetails,
	setSelectedSearch,
	setCheckedNoDoc,
	setTemporaryDocument,
} from "../../slice/document/document-slice";
import { resetPdf, setScrollTo, setPage, setFirstPage } from "../../slice/pdf/pdf-slice";
import { AdvancedSearch } from "./components";
import styles from "./ReviewHeader.module.css";
import { ApiService, ProjectDocumentsService } from "../../../../api";
import { useEffectOnce } from "../../../../common/hooks";
import SmartReviewContext from "../../context/smart-review-context";
import { NavUtils } from "../../../../navigation";
import { generateSearchPosition } from "../../utils/utils";
import { SOURCE_TYPES } from "../../../../common/constants";
import { SENTENCE_TYPE } from "../../constants/constants";
import { KEY_SMART_VIEW, KEY_SMART_VIEW_FILTER } from "../../../../common/providers/keys/keys";

export default function ReviewHeader({ selectedMenu }) {
	const navigate = useNavigate();
	const cancelTokenSourceRef = useRef(null);
	const [preventReload, setPreventReload] = useState(false);
	const [documentChanged, setDocumentChanged] = useState(false);
	const projectId = useSelector(({ context }) => context.project.id);
	const documents = useSelector(({ srProjectDocument }) => srProjectDocument.documents);
	const docMode = useSelector(({ srProjectDocument }) => srProjectDocument.mode);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const precedences = useSelector(({ srProjectDocument }) => srProjectDocument?.documents?.precedence);
	const temporaryDocument = useSelector(({ srDocument }) => srDocument.temporaryDocument);
	const selectedSearch = useSelector(({ srDocument }) => srDocument.selectedSearch);
	const multiDocs = useSelector(({ srProjectDocument }) => srProjectDocument.multiDocs?.contents || []);
	const isThereAnyDocument = useMemo(() => precedences.some((precedence) => precedence.count > 0), [precedences]);

	const dispatch = useDispatch();
	const location = useLocation();
	const params = useParams();

	const loadPrecedences = useCallback(
		() => {
			if (Array.isArray(documents[docMode]) && documents[docMode].length === 0) {
				ProjectDocumentsService.getAllPrecedences({ projectId }, cancelTokenSourceRef.current.token)
					.then((data) => {
						dispatch(setAllPrecedences(data));
					})
					.catch((err) => {
						console.error(err);
					});
			}
		},
		// eslint-disable-next-line
		[dispatch]
	);
	useEffect(() => {
		if (!cancelTokenSourceRef.current) {
			cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		}
		loadPrecedences();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [loadPrecedences]);
	useEffect(
		() => {
			if (documentChanged && documentId) {
				setDocumentChanged(false);
				navigate(
					NavUtils.goToSmartReview({
						projectId,
						documentId,
						pageStart: 1,
					}),
					{ state: location?.state }
				);
			}
		},
		// eslint-disable-next-line
		[documentChanged, documentId]
	);

	const fetchDocumentDetails = useCallback(
		({ docId, role }) => dispatch(getDocumentDetails({ docId, role, token: cancelTokenSourceRef.current.token })),
		[dispatch]
	);
	const fetchProjectDocumentDetails = useCallback(
		(docId) => dispatch(getDocumentInformation({ docId, token: cancelTokenSourceRef.current.token })),
		[dispatch]
	);
	const fetchPhysicalDetails = useCallback(
		(docId) => dispatch(getDocumentPhysicalDetails({ docId, token: cancelTokenSourceRef.current.token })),
		[dispatch]
	);
	const resetDocumentDetails = useCallback(() => dispatch(resetDocument()), [dispatch]);
	const handleDocumentSelection = useCallback(
		(doc) => {
			const { id, role, forced } = doc || {};
			if (forced) {
				setPreventReload(true);
			}
			batch(() => {
				resetDocumentDetails();
				dispatch(
					resetPdf(
						(doc.fromQa && generateSearchPosition(selectedSearch)) ||
							(doc.fromToc && generateSearchPosition(doc.coordinates)) ||
							null
					)
				);
				fetchDocumentDetails({ docId: id, role });
				fetchProjectDocumentDetails(id);
				fetchPhysicalDetails(id);
				if (!doc.fromQa && !doc.fromToc) {
					dispatch(setScrollTo({ page: 0 }));
				}
				setDocumentChanged(true);
			});
		},
		[
			dispatch,
			fetchPhysicalDetails,
			fetchDocumentDetails,
			fetchProjectDocumentDetails,
			resetDocumentDetails,
			selectedSearch,
		]
	);
	useEffect(
		() => {
			if (temporaryDocument?.id && temporaryDocument.id !== documentId) {
				handleDocumentSelection({ ...temporaryDocument, forced: true });
			}
		},
		// eslint-disable-next-line
		[temporaryDocument]
	);
	useEffect(() => {
		const paramDocId = Number.parseInt(params.docId, 10);
		if (paramDocId && documentId && paramDocId !== documentId && !preventReload) {
			handleDocumentSelection({ id: params.docId, role: "req" });
		}
	}, [documentId, params, handleDocumentSelection, preventReload]);

	const handleNoDoc = () => {
		if (multiDocs[0]?.id) {
			dispatch(setTemporaryDocument({ id: multiDocs[0].id }));
			dispatch(setClauseId(multiDocs[0].id));
			dispatch(setIsPdfScrolling(false));
		} else {
			dispatch(setCheckedNoDoc(true));
		}
	};

	const checkDocument = ({ docId, role, page, fallBack }) => {
		/* 2. Check if the url document is accessible or fallback */
		if (!Number.isNaN(Number.parseInt(docId, 10))) {
			resetDocumentDetails();
			dispatch(resetPdf());
			dispatch(setIsHandleScroll(true));
			fetchDocumentDetails({ docId, role }).then((data) => {
				const { requestStatus } = data.meta;
				if (requestStatus === "fulfilled") {
					fetchProjectDocumentDetails(docId);
					fetchPhysicalDetails(docId);
					const newPage = Number.parseInt(page, 10);
					const validPage = /^\d+$/.test(page) && newPage >= 0;
					dispatch(setFirstPage(validPage && newPage));
					navigate(
						NavUtils.goToSmartReview({
							projectId,
							documentId: docId,
							pageStart: (!validPage && 1) || newPage + 1,
						}),
						{ state: location?.state }
					);
					dispatch(setCheckedNoDoc(false));
					if (!validPage) {
						dispatch(setPage(0));
					}
				}
			});
		} else {
			fallBack();
		}
	};

	const checkDocumentFromHistory = () => {
		/* 3. Fallback of url check, checking document via context in localstorage (past visit)
			Or empty state */
		const srContext = SmartReviewContext.getContext();
		if (srContext && srContext?.project === projectId && srContext?.document) {
			checkDocument({
				docId: srContext.document,
				role: srContext?.role,
				page: srContext?.page || 0,
				fallBack: handleNoDoc,
			});
		} else if (!documentId) {
			handleNoDoc();
		}
	};
	useEffectOnce(
		/* 1. First landing on page, get document through url */
		() => {
			if (documents.precedence.some((prec) => prec.count > 0)) {
				const urlDocId = params?.docId || documentId;
				const urlPage = new URLSearchParams(location.search).get("page");
				const urlRole = new URLSearchParams(location.search).get("role");
				const urlInfoId = new URLSearchParams(location.search).get("infoId");
				const urlInfoType = new URLSearchParams(location.search).get("infoType");
				const urlSource = new URLSearchParams(location.search).get("origin");
				if (urlDocId) {
					if (urlInfoId) {
						dispatch(
							setSelectedSearch({
								pageStart: Number.parseInt(urlPage, 10),
								infoId: Number.parseInt(urlInfoId, 10),
								fromUrl: true,
								source: urlSource || null,
								isRequirement: urlInfoType === SENTENCE_TYPE.REQUIREMENT,
							})
						);
					}
					checkDocument({
						docId: urlDocId,
						role: urlRole,
						page: urlPage,
						fallBack: checkDocumentFromHistory,
					});
				} else {
					checkDocumentFromHistory();
				}
			}
		},
		[documents],
		() => documents?.precedence && documents.precedence.length > 0 && multiDocs.length > 0
	);

	useEffectOnce(
		() => {
			const { pathname } = location;
			const match = pathname.match(/\/projects\/(\d+)/);
			const hasChangedProject =
				match && Number.parseInt(match[1], 10) !== SmartReviewContext.getContext()?.project;
			const urlSource = new URLSearchParams(location.search).get("origin");
			if (
				[SOURCE_TYPES.DASHBOARD].includes(urlSource) &&
				location?.state?.filters &&
				location?.state?.selectedDocuments
			) {
				dispatch(setFilters({ ...location?.state?.filters }));
				dispatch(setTransverseFilters({ ...location?.state?.filters }));
				SmartReviewContext.updateFilterInStorage({ ...location?.state?.filters });
				SmartReviewContext.setDocumentSelections([...(location?.state?.selectedDocuments || [])]);
			} else if ([SOURCE_TYPES.DOC_CENTER, SOURCE_TYPES.CANVAS].includes(urlSource) || hasChangedProject) {
				dispatch(setFilters({ separator: "AND", Type: "QuickAccessSearchFilterDTO" }));
				dispatch(setTransverseFilters({ separator: "AND", Type: "QuickAccessSearchFilterDTO" }));
				window.sessionStorage.setItem(KEY_SMART_VIEW, null);
				window.sessionStorage.setItem(KEY_SMART_VIEW_FILTER, null);
			}
			dispatch(setIsContextLoadedToRedux(true));
		},
		[location],
		() => location
	);

	return (
		<>
			{isThereAnyDocument && (
				<div className={styles.container}>
					<AdvancedSearch selectedMenu={selectedMenu} />
				</div>
			)}
		</>
	);
}
