import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge, Menu, MenuItem, Popover } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
	CustomButton,
	icon,
	IconComponent,
	InfiniteList2,
	CustomIconButton,
	ViewTabs,
} from "../../../common/components";
import styles from "./MentionMenu.module.css";
import { ApiService, MentionsService } from "../../../api";
import { MentionBox } from "../menu-components";
import { NavUtils, isInMyReviewPage } from "../../utils";
import {
	setCommentData,
	setIsNewNotification,
	setSelectedSearch,
	setTemporaryDocument,
} from "../../../views/my-review/slice/document/document-slice";
import { translate } from "../../../common/providers";
import { SOURCE_TYPES } from "../../../common/constants";
import { setScrollTo } from "../../../views/my-review/slice/pdf/pdf-slice";
import { generateSearchPosition } from "../../../views/my-review/utils/utils";
import { SENTENCE_TYPE } from "../../../views/my-review/constants/constants";
import smartReviewContext from "../../../views/my-review/context/smart-review-context";

const MentionMenu = ({
	menuName,
	project = null,
	onClose = null,
	onOpen = null,
	open = false,
	selected = false,
	"data-testid": dataTestId = null,
}) => {
	const anchorRef = useRef(null);
	const cancelTokenSourceRef = useRef(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const [infiniteUnreadListRequest, setInfiniteUnreadListRequest] = useState(null);
	const [selectedTab, setSelectedTab] = useState(0);
	const [unreadMentionsCount, setUnreadMentionsCount] = useState(0);
	const [showMore, setShowMore] = useState(false);
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const isNewNotification = useSelector(({ srDocument }) => srDocument.isNewNotification);
	const menuOpen = Boolean(menuAnchorEl);
	const reviewDocumentId = useSelector(({ srDocument }) => srDocument.documentId);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const refreshMentions = useCallback(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		if (project && project.id) {
			const projectId = project.id;
			setInfiniteListRequest(
				() =>
					({ page, limit }) =>
						MentionsService.getUserMentionsByProject(
							{ projectId },
							{ page, limit, isRead: true },
							cancelTokenSourceRef.current.token
						)
			);
			setInfiniteUnreadListRequest(
				() =>
					({ page, limit }) =>
						MentionsService.getUserMentionsByProject(
							{ projectId },
							{ page, limit, isRead: false },
							cancelTokenSourceRef.current.token
						)
			);
			MentionsService.getUserUnreadMentionsCountByProject({ projectId }, cancelTokenSourceRef.current.token)
				.then((data) => setUnreadMentionsCount(data.value))
				.catch((err) => console.error(err));
		}

		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, [project]);
	useEffect(() => {
		refreshMentions();
	}, [refreshMentions, isNewNotification]);

	const goToMention = ({
		commentId,
		documentId,
		infoId,
		infoType,
		isOpenForDiscussion,
		pageNumber,
		parentCommentId,
		projectId,
		x1,
		x2,
		y1,
		y2,
	}) => {
		const isInMyReview = isInMyReviewPage(location);
		if (!isInMyReview) {
			navigate(
				NavUtils.goToSmartReview({
					projectId,
					documentId,
					pageStart: pageNumber,
					infoId,
					infoType,
					isOpenForDiscussion,
					commentId: parentCommentId || commentId,
				}),
				{ state: { previous: location.pathname } }
			);
		} else if (documentId !== reviewDocumentId) {
			dispatch(
				setTemporaryDocument({
					id: documentId,
					fromToc: true,
					coordinates: { page: pageNumber, y1 },
				})
			);
		}
		const payload = {
			pageStart: pageNumber,
			infoId,
			isRequirement: infoType === SENTENCE_TYPE.REQUIREMENT,
			commentId: parentCommentId || commentId,
			rectangles: [{ y1, x1, y2, x2 }],
			source: SOURCE_TYPES.COMMENTS,
			openSidePanel: true,
			commentOpenConversation: isOpenForDiscussion,
			fromUrl: true,
		};
		const handleDataSaving = () => {
			dispatch(setSelectedSearch(payload));
			smartReviewContext.setSidePanelInfo(payload);
			dispatch(
				setCommentData({
					sidePanelCommentId: parentCommentId || commentId,
					sidePanelCommentOpenConversation: isOpenForDiscussion,
				})
			);
			dispatch(setScrollTo({ ...generateSearchPosition(payload) }));
		};
		if (!isInMyReview || documentId !== reviewDocumentId) {
			setTimeout(() => {
				handleDataSaving();
			}, 1000);
		} else {
			handleDataSaving();
		}
		onClose();
		setAnchorEl(null);
	};
	const handleOpen = () => {
		onOpen(menuName);
		setAnchorEl(anchorRef.current);
		refreshMentions();
	};
	const changeReadStatus = (e, mention) => {
		e.stopPropagation();
		const mentionId = mention.id;
		MentionsService.changeReadStatus({ mentionId }, cancelTokenSourceRef.current.token)
			.then(() => {
				refreshMentions();
				dispatch(setIsNewNotification((prev) => !prev));
			})
			.catch((err) => console.error(err));
	};
	const markAllAsRead = () => {
		const projectId = project.id;
		MentionsService.markAllAsReadOnProject({ projectId }, cancelTokenSourceRef.current.token)
			.then(() => {
				refreshMentions();
				setMenuAnchorEl(null);
				dispatch(setIsNewNotification(false));
			})
			.catch((err) => console.error(err));
	};
	const handleClickOnMention = (mention) => {
		const projectId = project.id;
		const mentionId = mention.id;
		const {
			commentId,
			documentId,
			infoId,
			infoType,
			isOpenForDiscussion,
			pageNumber,
			parentCommentId,
			readStatus,
			x1,
			x2,
			y1,
			y2,
		} = mention;
		if (!readStatus) {
			MentionsService.changeReadStatus({ mentionId }, cancelTokenSourceRef.current.token)
				.then(() => {
					setUnreadMentionsCount(unreadMentionsCount - 1);
					goToMention({
						commentId,
						documentId,
						infoId,
						infoType,
						isOpenForDiscussion,
						pageNumber,
						parentCommentId,
						projectId,
						x1,
						x2,
						y1,
						y2,
					});
				})
				.catch((err) => console.error(err));
		} else {
			goToMention({
				commentId,
				documentId,
				infoId,
				infoType,
				isOpenForDiscussion,
				pageNumber,
				parentCommentId,
				projectId,
				x1,
				x2,
				y1,
				y2,
			});
		}
	};
	const handleMenuClick = (e) => {
		e.stopPropagation();
		setMenuAnchorEl(e.currentTarget);
	};
	const handleMenuClose = (e) => {
		e.stopPropagation();
		setMenuAnchorEl(null);
	};
	const rowRenderer = (mention) => (
		<MentionBox
			key={mention.id}
			changeReadStatus={changeReadStatus}
			handleClickOnMention={handleClickOnMention}
			mention={mention}
			setShowMoreMenu={setShowMore}
		/>
	);

	return (
		<>
			<CustomButton
				ref={anchorRef}
				className={` ${open && styles["dropDown--contained"]} ${styles.dropDownMenu} ${
					selected && styles["dropDownMenu--selected"]
				}
				`}
				color="secondary"
				data-testid={dataTestId}
				onClick={handleOpen}
			>
				<Badge
					badgeContent={unreadMentionsCount}
					classes={{
						badge: `${styles.badgeStyle} ${
							open ? styles.badgeStyleUnreadOpen : styles.badgeStyleUnreadClose
						}`,
					}}
					color="primary"
					data-testId="mention.bell.active"
					max={999}
					variant="dot"
				>
					<IconComponent
						color={`${
							(open && "var(--color-white)") ||
							(unreadMentionsCount > 0 && "var(--color-blue)") ||
							"var(--color-dark-grey-1)"
						}`}
						icon={icon.faBell}
						size="lg"
					/>
				</Badge>
			</CustomButton>
			<Popover
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				classes={{
					paper: styles.popoverPaper,
					root: `${styles.popoverRoot} ${showMore && styles.popoverRootShowMore}`,
				}}
				open={open}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				onClose={onClose}
			>
				<div className={styles.title_container}>
					<div className={styles.popover_title}>{translate("common:mention.project-notifications")}</div>
					<CustomIconButton
						btnClassName={styles.menu_mark_all_as_read_btn}
						className={styles.menu_mark_all_as_read}
						color="secondary"
						data-testid="mention_top_menu"
						icon={icon.faEllipsisH}
						iconColor="var(--color-dark-grey-2)"
						size="sm"
						onClick={handleMenuClick}
					/>
					<Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose}>
						<MenuItem onClick={markAllAsRead}>{translate("common:mention.read-all")}</MenuItem>
					</Menu>
				</div>
				<div className={styles.list_container}>
					<ViewTabs
						className={styles.tabs}
						level="first"
						selectedTab={selectedTab}
						tabs={[
							{ label: translate("common:mention.tab-all") },
							{ label: translate("common:mention.tab-unread") },
						]}
						onChange={(_, newTab) => setSelectedTab(newTab)}
					/>
					<InfiniteList2
						request={selectedTab ? infiniteUnreadListRequest : infiniteListRequest}
						rowRenderer={rowRenderer}
					/>
				</div>
			</Popover>
		</>
	);
};

export default MentionMenu;
