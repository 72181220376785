import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	CustomCircularProgress,
	CustomIconButton,
	CustomLink,
	CustomTable,
	CustomTableBody,
	CustomTableCell,
	CustomTableHead,
	CustomTableRow,
	CustomTooltip,
	icon,
	IconComponent,
	StatusBar,
} from "../../../../../../common/components";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../../../../common/providers";
import { isNonEmptyObject, searchInString, sortArray } from "../../../../../../common/utils";
import { NavUtils } from "../../../../../../navigation";
import styles from "./DashboardTable.module.css";
import OptionHeader from "./option-header/OptionHeader";
import { setCrossFilters } from "../../../../../../common/slice";

function HeaderIcon(tooltip, className, iconName = "") {
	return (
		<CustomTooltip classes={{ tooltip: styles.tooltip }} placement="bottom" title={tooltip}>
			<IconComponent className={className} icon={iconName} size="lg" />
		</CustomTooltip>
	);
}
function StickyCell({
	active,
	autoTranslate = "yes",
	children,
	className = "",
	colSpan = 1,
	direction,
	onSort,
	sortKey,
}) {
	return (
		<CustomTableCell
			active={active}
			className={className}
			colSpan={colSpan}
			direction={direction}
			sortKey={sortKey}
			translate={autoTranslate}
			onSort={onSort}
		>
			{children}
		</CustomTableCell>
	);
}

export default function DashboardTable({
	companies,
	details,
	filters,
	infiniteListRequest,
	loading,
	onChangeGroupByCompany,
	onChangeGroupByUser,
	onChangeNameFilter,
	onClearNameFilter,
	onSelectDetail = null,
	projectId,
	rows,
	setExternalStates,
	users,
}) {
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");
	const [sortedTableData, setSortedTableData] = useState(rows);
	const company = useSelector(({ context }) => context.company);
	const userId = useSelector(({ context }) => context.user.id);
	const reqFilters = useSelector(({ dashboard }) => dashboard.filters);
	const dashboardType = useSelector(({ dashboard }) => dashboard.dashboardType);
	const dashboardFilters = useSelector(({ dashboard }) => dashboard.filters);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		if (Array.isArray(rows) && rows.length > 0) {
			const rowsToSort =
				(reqFilters?.content &&
					reqFilters.content.length > 0 &&
					rows.filter((x) => searchInString(x.name, reqFilters.content))) ||
				rows;
			setSortedTableData(sortArray(rowsToSort, order, orderBy, 0));
		} else {
			setSortedTableData([]);
		}
	}, [details, filters?.name, filters?.details, reqFilters?.content, rows, order, orderBy]);

	const handleSort = (property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};
	const headers = useMemo(() => {
		const doc = {
			name: translate("dashboard.my-dashboard.table-head.document"),
			sortKey: "name",
			className: `${styles.header__documentName} ${styles.customHeaderCell}`,
		};
		const pages = {
			name: translate("dashboard.my-dashboard.table-head.pages"),
			sortKey: "pages",
			className: styles.customHeaderCell,
		};
		const reviewer = {
			name: translate("dashboard.my-dashboard.table-head.reviewer"),
			sortKey: "name",
			className: styles.customHeaderCell,
		};
		const role = {
			name: translate("dashboard.my-dashboard.table-head.role"),
			sortKey: "role",
			className: styles.customHeaderCell,
		};
		const validatorRole = {
			name: translate("dashboard.my-dashboard.table-head.validator-role"),
			sortKey: "role",
			className: styles.customHeaderCell,
		};
		const unreviewed = {
			name: HeaderIcon(
				translate("dashboards.my-dashboard.tooltip.to-be-reviewed"),
				styles["icon--toBeReviewed"],
				icon.faHourglassHalf
			),
			className: `${styles.customHeaderCell} ${styles["customHeaderCell--status"]}`,
			sortKey: "unreviewed",
		};
		const yes = {
			name: HeaderIcon(
				translate("dashboards.my-dashboard.tooltip.accepted"),
				styles["icon--accepted"],
				icon.faCheckCircle
			),
			className: `${styles.customHeaderCell} ${styles["customHeaderCell--status"]}`,
			sortKey: "yes",
		};
		const pending = {
			name: HeaderIcon(
				translate("dashboards.my-dashboard.tooltip.pending"),
				styles["icon--pending"],
				icon.faClock
			),
			className: `${styles.customHeaderCell} ${styles["customHeaderCell--status"]}`,
			sortKey: "pending",
		};
		const no = {
			name: HeaderIcon(
				translate("dashboards.my-dashboard.tooltip.denied"),
				styles["icon--denied"],
				icon.faTimesCircle
			),
			className: `${styles.customHeaderCell} ${styles["customHeaderCell--status"]}`,
			sortKey: "no",
		};
		const notme = {
			name: HeaderIcon(
				translate("dashboards.my-dashboard.tooltip.not-me"),
				styles["icon--notme"],
				icon.faNotEqual
			),
			className: `${styles.customHeaderCell} ${styles["customHeaderCell--status"]}`,
			sortKey: "notme",
		};
		const detailAction = {
			name: translate("dashboard.my-dashboard.table-head.reviewer-details"),
			className: styles.customHeaderCell,
		};
		const allReqs = {
			name: translate("dashboard.my-dashboard.table-head.all-req"),
			className: styles.customHeaderCell,
			sortKey: "allReqs",
		};
		const matchedFilters = {
			name: translate("dashboard.my-dashboard.table-head.matched-filters"),
			className: styles.customHeaderCell,
			sortKey: "done",
		};
		const totals = !isNonEmptyObject(reqFilters) ? [allReqs] : [allReqs, matchedFilters];
		const empty = { name: <span className={styles.table__iconHead} />, className: styles.customHeaderCell };
		let tab = [doc, pages, validatorRole, ...totals, unreviewed, yes, pending, no, notme, empty];
		if (filters?.company?.id === company.id && dashboardType === "company" && !details) {
			tab = [doc, pages, validatorRole, ...totals, unreviewed, yes, pending, no, notme, detailAction, empty];
		}
		if (Array.isArray(users)) {
			tab = [doc, pages, role, ...totals, unreviewed, yes, pending, no, notme, empty];
		}
		if ((!users && !companies) || details) {
			tab = [reviewer, role, ...totals, unreviewed, yes, pending, no, notme];
		}
		return tab;
	}, [users, companies, dashboardType, filters?.company, company, reqFilters, details]);

	const getRedirectState = (id, documentName, status) => {
		if (companies) {
			if (filters.company.id === company.id) {
				return {
					state: {
						filters: {
							separator: "AND",
							Type: "QuickAccessSearchFilterDTO",
							documents: {
								is: true,
								matchAll: true,
								elements: [id],
							},
							positionsFilter: {
								myValidation: { elements: [status], is: true, matchAll: true },
								separator: "OR",
							},
						},
						selectedDocuments: [{ id, documentName }],
						previous: location.pathname,
					},
				};
			}
			return {
				state: {
					filters: {
						separator: "AND",
						Type: "QuickAccessSearchFilterDTO",
						documents: {
							is: true,
							matchAll: true,
							elements: [id],
						},
						positionsFilter: {
							positions: {
								elements: [{ companyId: filters.company.id, status: [status] }],
								is: true,
								matchAll: true,
							},
							separator: "OR",
						},
					},
					selectedDocuments: [{ id, documentName }],
					previous: location.pathname,
				},
			};
		}
		return {
			state: {
				filters: {
					separator: "AND",
					Type: "QuickAccessSearchFilterDTO",
					documents: {
						is: true,
						matchAll: true,
						elements: [id],
					},
					positionsFilter: {
						[filters.user.id === userId ? "myReview" : "othersReview"]: {
							elements: [status],
							is: true,
							matchAll: true,
						},
						separator: "OR",
					},
				},
				selectedDocuments: [{ id, documentName }],
				previous: location.pathname,
			},
		};
	};

	const updateCrossFilters = () => {
		if (dashboardFilters) {
			const formatedFilters = {
				isTaggedByClient: dashboardFilters.isClientRequirement,
				criticalities:
					(dashboardFilters.reqCriticality?.is || undefined) && dashboardFilters.reqCriticality?.elements,
				negotiabilities:
					(dashboardFilters.reqNegotiability?.is || undefined) && dashboardFilters.reqNegotiability?.elements,
				types: (dashboardFilters.reqType?.is || undefined) && dashboardFilters.reqType?.elements,
			};
			Object.keys(formatedFilters).forEach((k) => {
				if (formatedFilters[k] === undefined) {
					delete formatedFilters[k];
				}
			});
			if (isNonEmptyObject(formatedFilters)) {
				dispatch(setCrossFilters(formatedFilters));
			}
		}
	};
	const reviewRedirection = (id) => {
		if (id && isSegFeatureEnabled(SegFlags.SMART_REVIEW)) {
			updateCrossFilters();
			navigate(
				NavUtils.goToSmartReview({
					projectId,
					documentId: id,
					source: (isNonEmptyObject(reqFilters) && "dashboard") || null,
				}),
				{ state: { previous: location.pathname } }
			);
		}
	};

	const handleClickDetail = (e, row) => {
		e.stopPropagation();
		onSelectDetail(row);
	};
	const handleClickStatus = (e, id, documentName, status) => {
		e.stopPropagation();
		navigate(
			NavUtils.goToSmartReview({
				projectId,
				documentId: id,
				source: (isNonEmptyObject(reqFilters) && "dashboard") || null,
			}),
			getRedirectState(id, documentName, status)
		);
	};
	return (
		<div>
			{filters && (
				<OptionHeader
					companies={companies}
					details={details}
					filters={filters}
					infiniteListRequest={infiniteListRequest}
					setExternalStates={setExternalStates}
					onChangeGroupByCompany={onChangeGroupByCompany}
					onChangeGroupByUser={onChangeGroupByUser}
					onChangeNameFilter={onChangeNameFilter}
					onClearNameFilter={onClearNameFilter}
				/>
			)}
			<CustomTable>
				<CustomTableHead>
					<CustomTableRow>
						{headers.map((header, index) => {
							const key = index;
							return (
								<StickyCell
									key={key}
									active={orderBy === header.sortKey}
									className={header.className}
									direction={order}
									sortKey={header.sortKey}
									onSort={() => {
										handleSort(header.sortKey);
									}}
								>
									{header.name}
								</StickyCell>
							);
						})}
					</CustomTableRow>
				</CustomTableHead>
				<CustomTableBody
					emptyState={
						((users || companies) && (
							<span translate="no">
								{translate(
									(users && "dashboard.my-dashboard.table.no-doc-content.user") ||
										"dashboard.my-dashboard.table.no-doc-content.company",
									{ name: users ? filters.user?.displayName : filters.company?.name }
								)}
								&nbsp;
								{isSegFeatureEnabled(SegFlags.RIGHTS_ON_DOCUMENTS) && (
									<CustomLink to={NavUtils.goToRightsOnDocuments(projectId)}>
										{translate("dashboard.my-dashboard.table.no-doc-content.here")}
									</CustomLink>
								)}
							</span>
						)) ||
						translate("dashboard.my-dashboard.table.no-user-content")
					}
					isLoading={loading}
				>
					{Array.isArray(sortedTableData) &&
						sortedTableData.map((row) => (
							<CustomTableRow
								key={`${row.id}_${row.role}_${row.name}`}
								hover
								className={!isSegFeatureEnabled(SegFlags.SMART_REVIEW) ? styles["non-clickable"] : ""}
								onClick={() => {
									if (isSegFeatureEnabled(SegFlags.SMART_REVIEW)) {
										reviewRedirection(row.id);
									}
								}}
							>
								{({ isHovering }) => (
									<>
										<StickyCell className={styles.tableCell__documentName}>
											<div>{row.name}</div>
										</StickyCell>
										{(users || companies) && <StickyCell>{row.pages}</StickyCell>}
										<StickyCell autoTranslate="no" className={styles.tableCell__role}>
											{row.role}
										</StickyCell>
										<StickyCell>
											<CustomCircularProgress
												size="medium"
												total={row.totalReqs}
												value={row.allReqs}
											/>
										</StickyCell>
										{isNonEmptyObject(reqFilters) && (
											<StickyCell>
												<CustomCircularProgress
													size="medium"
													strokeColor="var(--color-dark-blue)"
													total={row.total}
													value={row.done}
												/>
											</StickyCell>
										)}
										<StickyCell colSpan={5}>
											<StatusBar
												accepted={row.yes}
												denied={row.no}
												id={row.id}
												name={row.name}
												notMe={row.notme}
												pending={row.pending}
												size="lg"
												toBeReviewed={row.unreviewed}
												onClickStatus={handleClickStatus}
											/>
										</StickyCell>
										{companies && filters?.company?.id === company?.id && (
											<StickyCell className={styles.tableCell__placeHolder}>
												<CustomIconButton
													icon={icon.faUserFriends}
													size="lg"
													onClick={(e) => {
														handleClickDetail(e, row);
													}}
												/>
											</StickyCell>
										)}
										{(users || companies) && isSegFeatureEnabled(SegFlags.SMART_REVIEW) && (
											<StickyCell className={styles.tableCell__placeHolder}>
												{(row.id && isHovering && (
													<CustomIconButton icon={icon.faArrowRight} size="lg" />
												)) ||
													""}
											</StickyCell>
										)}
									</>
								)}
							</CustomTableRow>
						))}
				</CustomTableBody>
			</CustomTable>
		</div>
	);
}
